/**
 * @prettier
 */

import { getSupportEmail } from 'utils/etc'

export const Validation = {
  CSS_MESSAGE: 'form-validation',
  JS_MESSAGE: 'js-validation',
  ERROR: 'validation-error',
  FLASH: 'js-validation-flash',
  initialized: false,

  fail(response, isScroll, showFlash = true) {
    if (isScroll === null) {
      isScroll = true
    }
    if (!response) {
      return
    }
    this.clear()
    if (showFlash) {
      if (response.base) {
        this.showErrorFlash(response.base[0])
      } else {
        this.showErrorFlash()
      }
    }
    _.forEach(response, (value, key) => {
      value = _.isArray(value) ? value.join(', ') : value
      this.failCustomBlock(key)
      this.failCustomMessage(key, value)
      this.failDataField(key, value)
      this.failField(key, value)
    })

    if (isScroll) {
      this.scrollToField(response)
    }
  },

  succeed(text) {
    setTimeout(() => {
      $(`.${this.JS_MESSAGE}`).hide()
      $(`.${this.ERROR}`).removeClass(this.ERROR)
      this.showSuccessFlash(text)
    }, 500)
  },

  timer: null,

  clear() {
    $(`.${this.JS_MESSAGE}`).hide()
    $(`.${this.ERROR}`).removeClass(this.ERROR)
  },

  clearCustomBlock(name) {
    $(`[data-validate-block='${name}']`).removeClass(this.ERROR)
  },

  clearCustomMessage(name) {
    $(`[data-validate-message='${name}']`)
      .removeClass(`${this.JS_MESSAGE} ${this.CSS_MESSAGE}`)
      .slideUp()
      .empty()
  },

  failCustomBlock(name) {
    $(`[data-validate-block='${name}']`).addClass(this.ERROR)
  },

  failCustomMessage(name, value) {
    const prefix = $(`[data-validate-message='${name}']`).data('prefix')

    if (prefix) {
      value = `${prefix} ${value}`
    }

    $(`[data-validate-message='${name}']`)
      .addClass(`${this.JS_MESSAGE} ${this.CSS_MESSAGE}`)
      .html(`${value}`)
      .show(300)
  },

  failDataField(name, value) {
    if (!value) {
      return
    }
    const prefix = $(`[data-validate='${name}']`).data('prefix')

    if (prefix) {
      value = `${prefix} ${value}`
    }

    $(`[data-validate='${name}']`)
      .addClass(this.ERROR)
      .after(`<div class='${this.CSS_MESSAGE} ${this.JS_MESSAGE}'>${value}</div>`)
  },

  failField(name, value) {
    const $target = $(`[name='${name}']`)
    const prefix = $target.data('prefix')

    if ($target.data('skip-validation')) {
      return
    }

    if (prefix) {
      value = `${prefix} ${value}`
    }
    $(`[name='${name}']`)
      .addClass(this.ERROR)
      .after(`<div class='${this.CSS_MESSAGE} ${this.JS_MESSAGE}'>${value}</div>`)
  },

  showErrorFlash(text = 'Error saving changes', delay = 6000) {
    UINotifications.add({
      text,
      type: 'error',
      delay,
    })
  },

  showErrorSupportFlash(
    text = `Something went wrong, please contact ${getSupportEmail()}`,
    delay,
  ) {
    this.showErrorFlash(text, delay)
  },

  showSuccessFlash(text = 'Changes have been saved', delay = 6000) {
    UINotifications.add({
      text,
      type: 'success',
      delay,
    })
  },

  findVisibleElement(elements) {
    return _.find(elements, el => $(el).is(':visible'))
  },

  scrollToField(response) {
    const attrs = [
      'name',
      'data-validate',
      'data-validate-message',
      'data-validate-block',
    ]
    const attrValue = _.isPlainObject(response) ? _.keys(response) : response
    const selector = attrs.map(attr => `[${attr}='${attrValue.join(`'],[${attr}='`)}']`)
    const elements = $(selector.join(','))
    const visibleElement = this.findVisibleElement(elements)

    if (!visibleElement) {
      return
    }

    $('html').animate({ scrollTop: $(visibleElement).offset().top - 100 }, 500)
  },
}
